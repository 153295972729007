import { decamelize } from "humps";

export const convertServerErrorToForm = (serverErrors, setErrorFn) => {
  if (serverErrors?.messages) {
    setErrorFn("form", {
      type: "server",
      message: serverErrors.messages.join("\n"),
    });
  }
  if (serverErrors?.fieldMessages) {
    serverErrors.fieldMessages.map((fieldError: any) => {
      const parseField = (error, parentKey = "") => {
        let key = decamelize(Object.keys(error)[0]);
        if (parentKey) {
          key = `${parentKey}.${key}`;
        }
        const value = Object.values(error)[0] as [string];
        if (Array.isArray(value) && typeof value[0] === "object") {
          value.map((subValue: any) => {
            parseField(subValue, key);
            return null;
          });
        } else {
          setErrorFn(key, {
            type: "server",
            message: value.join("\n"),
          });
        }
      };
      parseField(fieldError);
    });
  }
};

export const formatAndConvertServerErrorToForm = (
  errorResponse,
  setErrorFn
) => {
  // エラー形式を合わせるために変換している
  if (Array.isArray(errorResponse.errors)) {
    const errorMessages = errorResponse.errorMessages;
    convertServerErrorToForm({ messages: errorMessages }, setErrorFn);
  } else {
    const errors = Object.keys(errorResponse.errors).map((key) => {
      if (key === "base") {
        // サーバーからbaseで返された場合はformに変換
        return { form: errorResponse.errors[key] };
      }
      return { [key]: errorResponse.errors[key] };
    });
    convertServerErrorToForm({ fieldMessages: errors }, setErrorFn);
  }
};

export const sanitizePassportNameInput = (value) =>
  value ? value.trim().toUpperCase() : "";
