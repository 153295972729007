import * as React from "react";
import { css } from "@emotion/react";
import { isObjectEmpty } from "main/javascripts/utils/ObjectUtil";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { ValidationErrorsBlock } from "./ValidationErrorsBlock";
import {
  accentColor,
  hex2rgba,
  shadeColorLog,
} from "main/javascripts/styles/base/colorStyle";
import { borderRadius } from "main/javascripts/styles/base/borderStyle";
import { SubmitErrorsBlock } from "./SubmitErrorsBlock";
import { ErrorCodes } from "main/javascripts/constants/ErrorCodes";

export interface IProps {
  namespace: string;
  errors?: any;
  hideValidationErrors?: boolean;
  hideTitle?: boolean;
}

export const FormErrorsBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement | null => {
  const {
    namespace,
    errors,
    hideValidationErrors = false,
    hideTitle = false,
  } = props;
  if (!errors || isObjectEmpty(errors)) {
    return null;
  }

  const { form, ...validationErrors } = errors;
  const isFormError = !!form; // form keyに全体のエラーを格納する
  const formErrorMessages =
    isFormError && isObjectEmpty(validationErrors)
      ? [errors?.form.message]
      : null;
  const hasTitle = !hideTitle || formErrorMessages?.length > 0;
  const errorCode = isFormError ? null : ErrorCodes.form;

  const validationErrorsBlockStyles = hasTitle
    ? validationErrorsBlockStyle
    : validationErrorsBlockWithoutTitleStyle;

  return (
    <div css={blockStyle}>
      <SubmitErrorsBlock
        errorCode={errorCode}
        errorMessages={formErrorMessages}
        hideTitle={hideTitle}
      />
      {validationErrors &&
        !isObjectEmpty(validationErrors) &&
        !hideValidationErrors && (
          <div css={validationErrorsBlockStyles}>
            <ValidationErrorsBlock
              namespace={namespace}
              errors={validationErrors}
            />
          </div>
        )}
    </div>
  );
};

const blockStyle = css`
  background: ${shadeColorLog(hex2rgba(accentColor.alertColor), 0.8)};
  border-radius: ${borderRadius.normal};
  margin: ${space.atom} ${space.atom} ${space.atom};
`;
const validationErrorsBlockStyle = css`
  margin-top: -${space.atom};
`;
const validationErrorsBlockWithoutTitleStyle = css`
  margin-top: 0;
`;
